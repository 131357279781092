<!-- Footer section starts -->

<div class="container-fluid footer-text-section">
    <div class="row footer-text-row">
        <div class="col footer-text-div">
            <span class="footer-text" style="text-align: center;"> ©{{rightsYear}} All Right Reserved
            </span>
        </div>
    </div>
</div>

<!-- Footer section ends -->