import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class APIService {

    constructor(private http: HttpClient) { }
    sendForm(payload:any) {
        
        return this.http.post<any>(environment.BASE_URL, payload)
            .pipe(
                catchError((err) => {
                console.log('Error in service: ');
                console.log(err);
                return throwError(err);
                })
            )
    }
    
}