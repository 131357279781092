export class Constants {

    public static TFN = {

        DEFAULT_TFN: '1-866-204-4992',
        INTERNET_DEALS_CALL_TFN: '1-866-613-9279',
        INTERNET_DEALS_TFN: '1-866-613-9279'

    }


}