import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataFlowService } from '../../../shared/services/data-flow.service';


@Component({
  selector: 'app-internet-deals-header',
  templateUrl: './internet-deals-header.component.html',
  styleUrls: ['./internet-deals-header.component.css']
})
export class InternetDealsHeaderComponent implements OnInit {

  constructor(public dataService: DataFlowService,) { }

  ngOnInit(): void {
   
  }

}
