import { NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternetDealsComponent } from './internet-deals.component';
import { InternetDealsRoutingModule } from './internet-deals-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  declarations: [InternetDealsComponent],
  imports: [
    CommonModule,
    InternetDealsRoutingModule,
    CarouselModule,
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]
})
export class InternetDealsModule { }
