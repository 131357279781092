import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DataFlowService } from '../../shared/services/data-flow.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LeadFormComponent } from '../../shared/components/lead-form/lead-form.component';
import { OwlOptions,SlidesOutputData } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-internet-deals',
  templateUrl: './internet-deals.component.html',
  styleUrls: ['./internet-deals.component.css']
})
export class InternetDealsComponent implements OnInit {
  comcastBusinessInternetServicesSlide: any;
  activeSlides: SlidesOutputData ={};
  //slidesStore: any[] ={};

  customOptions: OwlOptions = {
    center: true,
    loop: true,
    margin: 20,
    autoplay: true,
    autoplaySpeed: 2000,
    // mouseDrag: false,
    // touchDrag: false,
    // pullDrag: false,
    dots: true,
    dotsEach: true,
    navSpeed: 2000,
    //nav: false,
    //nav: true,
    //navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
    startPosition: 0,
    responsive: {
      0: {
        items: 1
      },
      324: {
        items: 1
      },
      550: {
        items: 2
      },
      767: {
        items: 3
      },
      1024: {
        items: 3
      },
    }
  }

getData(data: SlidesOutputData) {
        this.activeSlides = data;
        console.log(this.activeSlides);
      }

  tfn = '';
  constructor(public RouterModule: RouterModule,
    private titleService: Title, public dataService: DataFlowService,
    private meta: Meta,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Comcast Internet Deals");
    this.tfn = this.dataService.CallTFN;

    this.meta.updateTag(
      { name: 'description', content: 'Comcast Internet Deals' }
    );

  }

  openModal() {
    const modalRef = this.modalService.open(LeadFormComponent, { size: 'lg' });
    modalRef.componentInstance.isModal = true;
  }

}
