import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internet-deals-footer',
  templateUrl: './internet-deals-footer.component.html',
  styleUrls: ['./internet-deals-footer.component.css']
})
export class InternetDealsFooterComponent implements OnInit {

  rightsYear: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.rightsYear = new Date().getFullYear();
  }

}
