import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class DataFlowService {

    CallTFN!: string; // = Constants.TFN.DEFAULT_TFN;
    TFN!: string; // = Constants.TFN.DEFAULT_TFN.replace('-', '');
    paramTFN!: boolean; // = Constants.TFN.DEFAULT_TFN.replace('-', '');
    queryParam!: string;
    queryCallTFN!: string;
    queryTFN!: string;
    
    constructor() { }

}