import { Component, OnInit } from '@angular/core';
import { Constants } from './shared/utilities/constants';
import { ActivatedRoute, Router, NavigationEnd, Params, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DataFlowService } from './shared/services/data-flow.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LeadFormComponent } from './shared/components/lead-form/lead-form.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor( private activeRouter: ActivatedRoute, private router: Router, public dataService: DataFlowService, private modalService: NgbModal) { }

  url:string = '/';
  rightsYear: number = new Date().getFullYear();

  ngOnInit(): void {
    
    window.onbeforeunload = function () {
      window.onunload = function () {              
        localStorage.removeItem('tfn');
        localStorage.removeItem('affiliateID');
        localStorage.removeItem('campaignID');
        localStorage.removeItem('EZAgent');             
      }
      //return undefined;
    };

    this.router.events
    .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
    .subscribe(event => {
      
      this.url = event.url;
      //First if condition for a page to be hit directly through the URL.. Not navigation link
      if (event.id === 1 && event.url === event.urlAfterRedirects) {        
        this.activeRouter.queryParams.subscribe(params => {
          this.url = this.router.url; 
          //Check for TFN in query string
          if (params.getQuote == "true") {
            localStorage.setItem('getQuote', params.getQuote)
            const modalRef = this.modalService.open(LeadFormComponent, { size: 'lg' })
            modalRef.componentInstance.isModal = true
          }

          if (params.campaignID) {
            localStorage.setItem('campaignID', params.campaignID)
          }

          if (params.secondaryLeadSource) {
            localStorage.setItem('secondaryLeadSource', params.secondaryLeadSource)
          }
          
          if (params.tfn) {
            this.dataService.CallTFN = params.tfn;
            this.dataService.TFN = this.dataService.CallTFN.toString().replace(/-/g, '');            
            localStorage.setItem('tfn', params.tfn);
            }

          //Check for TFN in local storage
          else if (localStorage.getItem('tfn')) {            
            const tfn: any = localStorage.getItem('tfn');
            this.dataService.CallTFN = tfn;
            this.dataService.TFN = tfn.toString().replace(/-/g, '');
          }

          //Apply TFN from constants
          else {
            localStorage.removeItem('tfn');
            localStorage.removeItem('affiliateID');
            localStorage.removeItem('campaignID');
            localStorage.removeItem('EZAgent');
            localStorage.removeItem('getQuote');
            localStorage.removeItem('secondaryLeadSource');
            
            //Add URLs for default TFN starts

            if(this.router.url == "/" || this.router.url == "/home")
            {
              this.dataService.CallTFN = Constants.TFN.DEFAULT_TFN;
              this.dataService.TFN = Constants.TFN.DEFAULT_TFN.replace(/-/g, '');
            }
            else if(this.router.url == "/lead-flow")
            {
              this.dataService.CallTFN = Constants.TFN.DEFAULT_TFN;
              this.dataService.TFN = Constants.TFN.DEFAULT_TFN.replace(/-/g, '');
            }
            else if(this.router.url == "/thank-you")
            {
              this.dataService.CallTFN = Constants.TFN.DEFAULT_TFN;
              this.dataService.TFN = Constants.TFN.DEFAULT_TFN.replace(/-/g, '');
            }
            else if(this.router.url == "/contact-us")
            {
              this.dataService.CallTFN = Constants.TFN.DEFAULT_TFN;
              this.dataService.TFN = Constants.TFN.DEFAULT_TFN.replace(/-/g, '');
            }
            else if(this.router.url == "/ethernet-dedicated-internet")
            {
              this.dataService.CallTFN = Constants.TFN.DEFAULT_TFN;
              this.dataService.TFN = Constants.TFN.DEFAULT_TFN.replace(/-/g, '');
            }
            else if(this.router.url == "/internet-deals")
            {
              this.dataService.CallTFN = Constants.TFN.INTERNET_DEALS_TFN;
              this.dataService.TFN = Constants.TFN.INTERNET_DEALS_CALL_TFN.replace(/-/g, '');
            }
            
            //Add URLs for default TFN ends   

          }

          if (params.affiliateID) {            
            localStorage.setItem('affiliateID', params.affiliateID);
          }

          if (params.campaignID) {            
            localStorage.setItem('campaignID', params.campaignID);
          }

          if (params.EZAgent) {            
            localStorage.setItem('EZAgent', params.EZAgent);
          }
          
          if (params.secondaryLeadSource) {            
            localStorage.setItem('secondaryLeadSource', params.secondaryLeadSource);
          }        
          if (params.getQuote) {            
            localStorage.setItem('getQuote', params.getQuote);
          }
        });

      }
      //Else condition for a page to be accessed through navigation link 
      else {
        
        //Check for TFN in local storage
        if (localStorage.getItem('tfn')) {            
          const tfn: any = localStorage.getItem('tfn');
          this.dataService.CallTFN = tfn;
          this.dataService.TFN = tfn.toString().replace(/-/g, '');
        }

        //Apply TFN from constants
        else {
          localStorage.removeItem('tfn');
          localStorage.removeItem('affiliateID');
          localStorage.removeItem('campaignID');
          localStorage.removeItem('EZAgent');
          localStorage.removeItem('secondaryLeadSource');
          localStorage.removeItem('getQuote');
        
          //Add URLs for default TFN starts
          this.url = this.router.url; 
          if(this.router.url == "/home")
          {
            this.dataService.CallTFN = Constants.TFN.DEFAULT_TFN;
            this.dataService.TFN = Constants.TFN.DEFAULT_TFN.replace(/-/g, '');
          }
          else if(this.router.url == "/lead-flow")
          {
            this.dataService.CallTFN = Constants.TFN.DEFAULT_TFN;
            this.dataService.TFN = Constants.TFN.DEFAULT_TFN.replace(/-/g, '');
          }
          else if(this.router.url == "/thank-you")
          {
            this.dataService.CallTFN = Constants.TFN.DEFAULT_TFN;
            this.dataService.TFN = Constants.TFN.DEFAULT_TFN.replace(/-/g, '');
          }
          else if(this.router.url == "/contact-us")
          {
            this.dataService.CallTFN = Constants.TFN.DEFAULT_TFN;
            this.dataService.TFN = Constants.TFN.DEFAULT_TFN.replace(/-/g, '');
          }
          else if(this.router.url == "/ethernet-dedicated-internet")
          {
            this.dataService.CallTFN = Constants.TFN.DEFAULT_TFN;
            this.dataService.TFN = Constants.TFN.DEFAULT_TFN.replace(/-/g, '');
          }
          else if(this.router.url == "/internet-deals")
          {
            this.dataService.CallTFN = Constants.TFN.INTERNET_DEALS_TFN;
            this.dataService.TFN = Constants.TFN.INTERNET_DEALS_CALL_TFN.replace(/-/g, '');
          }
          
          //Add URLs for default TFN ends   
        }
      }
    });


  }

  openModal() {
    const modalRef = this.modalService.open(LeadFormComponent, { size: 'lg' });
    modalRef.componentInstance.isModal = true;
  }

}
