import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DataFlowService } from '../../services/data-flow.service';
import { APIService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lead-form',
  templateUrl: './lead-form.component.html',
  styleUrls: ['./lead-form.component.css']
})
export class LeadFormComponent implements OnInit {


  @Input() submitButtonText = 'Get Free Quote Now';
  isModal = false;
  leadForm!: FormGroup;
  public submitted: boolean = false;
  public loading: boolean = false;
  
  tfn = '(866) 204-4992';
  leadSource: any = 'Comcast Branded';
  affiliateID: any = '';
  campaignID: any = 'Comcast Branded';
  ezAgent: any = '';
  secondaryLeadSource: any = '';
  pageSource: any = '';

  constructor(private form: FormBuilder,
    private dataservice: DataFlowService, 
    private apiservice: APIService, 
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit(): void {

    this.leadForm = this.form.group({
      name: ['', Validators.required],
      businessName: ['', Validators.required],
      businessAddress: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: [''],
    });

    let currentPath = window.location.pathname;

    if (currentPath == '' || currentPath == '/' || currentPath == '/home' || currentPath == undefined) {
      this.pageSource = 'Comcast Business Savings';
    }

    this.tfn = this.formattfn(this.dataservice.CallTFN);

    this.route.queryParams.subscribe(params => {
      if(params['tfn']) {
        this.tfn = this.formattfn(params['tfn'])
      }
      if(params['affiliateID']) {
        this.affiliateID = params['affiliateID']
      }
      if(params['campaignID']) {
        this.campaignID = params['campaignID']
      }
      if(params['secondaryLeadSource']) {
        this.secondaryLeadSource = params['secondaryLeadSource']
      }
    });
  }

  getControlByName(control: string) {
    return this.leadForm.get(control);
  }

  get leadFormDetails() {
    return this.leadForm.controls;
  }

  formattfn(phoneNumber: string) {
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    if (cleaned.length == 11) {
      cleaned = cleaned.substring(1);
    }
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return '';
  }

  formatPhoneNumber(phoneNumber: string) {
    let formattedNumber = ''
    var cleaned = ('' + phoneNumber).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      formattedNumber = '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    this.getControlByName('phone')?.setValue(formattedNumber);
  }

  onCLose() {
    this.modalService.dismissAll();
  }

  onLeadFormClick() {

    this.submitted = true;
    this.loading = true;

    if (this.leadForm.invalid) {
      this.loading = false;
      return;
    }

    this.sendLeadForm();

    this.submitted = false;
    this.loading = false;
    this.leadForm.reset();

  }

  sendLeadForm(): any {

    let payload = {
      source: "CBS",
      lead: {
        firstName: this.getControlByName('name')?.value?.split(' ').slice(0, 1).join(' '),
        lastName: this.getControlByName('name')?.value?.split(' ').slice(1).join(' '),
        companyName: this.leadFormDetails.businessName.value,
        address1: this.leadFormDetails.businessAddress.value,
        phone: this.leadFormDetails.phone.value,
        email: this.leadFormDetails.email.value,
        meta: [
          {
            name: "Notes",
            value: this.leadFormDetails.message.value
          },
          {
            name: "Agency",
            value: "Clear Connect"
          },
          {
            name: "LeadSource",
            value: this.campaignID
          },
          {
            name: "Lead Source Call #",
            value: this.tfn
          },
          {
            name: "Campaign Id",
            value: this.campaignID
          },
          ...(this.campaignID != "Comcast Branded"
            ? [
                {
                  name: this.campaignID + " Secondary Lead Source",
                  value: this.secondaryLeadSource
                }
              ]
            : []),
          {
            name: "EZ Button Agent",
            value: this.ezAgent
          },
          {
            name: "Page Source",
            value: this.pageSource
          }

        ]
      }
    }

    this.apiservice.sendForm(payload)
      .subscribe(
        (res) => {
          //console.log(res);
          //alert("Thank you for submitting the form!")
          this.router.navigate(['/thank-you']);
          this.modalService.dismissAll();
        },
        (error) => {
          alert("Error in form submission! Kindly re-submit with valid data. Thank You.");
          throw error;
        }
      )
  }

}
