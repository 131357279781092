import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InternetDealsComponent } from './pages/internet-deals/internet-deals.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'lead-flow', loadChildren: () => import('./pages/lead-flow/lead-flow.module').then(m => m.LeadFlowModule) },
  { path: 'thank-you', loadChildren: () => import('./pages/thank-you/thank-you.module').then(m => m.ThankYouModule) },
  { path: 'contact-us', loadChildren: () => import('./pages/contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: 'internet-deals', loadChildren: () => import('./pages/internet-deals/internet-deals.module').then(m => m.InternetDealsModule) },
  { path: 'ethernet-dedicated-internet', loadChildren: () => import('./pages/ethernet-dedicated-internet/ethernet-dedicated-internet.module').then(m => m.EthernetDedicatedInternetModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
