<!-- Internet Deals bar starts -->

<div class="container-fluid header" id="myHeader">

    <div class="row comcast-bar-row ">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-3 comcast-bar-img-div">
            <a [routerLink]="['/']"><img class="comcast-bar-logo" alt="comcast logo" width="" height=""
                    src="../../../../assets/images/internet-deals/comcast-logo.jpg" alt="Comcast Business Logo" />
            </a>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-5 col-lg-6 bar-nav-div">
            <nav class='navbar navbar-expand-md navbar-custom navbar-dark'>

                <div id='navbarCollapse' class=' navbar-collapse justify-content-center add-top-space'>
                    <div class='navbar-nav'>

                        <li class='nav-item right-border'>
                            <img src="../../../../assets/images/internet-deals/24-7-comcast-customer-service.png"
                                style="margin-right: 10px;">
                            <a class='customer-link' [routerLink]="['/contact-us']">
                                Customer Service</a>


                        </li>
                        <li class='nav-item customer-text'>
                            <p>Call now for best Comcast <br/>
                                Internet prices in your area</p>
                        </li>
                    </div>
                </div>
            </nav>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-5 col-lg-3 cox-bar-tfn-div">
            <div class="tfn-box-div">
                <p class="tfn-text">
                    <a class="spectrum-tfn btn btn-primary" href="tel:+{{this.dataService.TFN}}">
                        <i class="fa fa-phone fa-flip-horizontal"></i> {{this.dataService.CallTFN}}</a>
                </p>
            </div>
        </div>
    </div>

</div>

<!-- Internet Deals bar ends -->