<app-internet-deals-header></app-internet-deals-header>
<!-- Header starts -->

<!-- <div class="header-section" *ngIf="url != '/internet-deals'">
    <div class="container-fluid">
        <div class="row header-row">
            <div class="col-xs-6 col-sm-6 col-md-3 header-logo-div">
                <img [routerLink]="['/']" class="header-logo" src="../assets/images/home/comcast-business-solutions-provider-logo.png" alt="Comcast Business Logo" />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-5 header-free-quote-div"> -->
                <!-- <button class="header-free-quote-btn" (click)="openModal()">Get Free Quote</button> -->
            <!-- </div>
            <div class="col-xs-12 col-sm-12 col-md-4 header-tfn-div">
                <p class="header-tfn-text">Call for Best Pricing</p>
                <p class="header-tfn-text">In Your Area</p>
                <a class="header-tfn" href="tel:+{{this.dataService.TFN}}">{{this.dataService.CallTFN}}</a>
                <p class="header-tfn-text-sm"><a href="tel:+18663956036">Para español 1-866-395-6036</a></p>
            </div>
        </div>
    </div>
</div> -->

<!-- Header ends -->



<!-- Page body starts -->

<router-outlet>



</router-outlet>

<!-- Page body ends -->


<!-- Footer starts -->

<app-internet-deals-footer *ngIf="url == '/internet-deals'"></app-internet-deals-footer>
<div class="footer-section" *ngIf="url != '/internet-deals'">
    <div class="container-fluid">
        <div class="row footer-row">
            <div class="col-12 footer-div">
                <p class="footer-text">Copyright {{rightsYear}}. All Rights Reserved.</p>
            </div>
        </div>
    </div>
</div>

<!-- Footer ends -->