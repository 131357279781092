// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  setup: "dev",
  BASE_URL: "https://fwr9dllugb.execute-api.us-west-1.amazonaws.com/staging/proxy",
  ZIP_SEARCH_URL: "https://tp6a0n0wh8.execute-api.us-east-1.amazonaws.com/staging/ClearConnectZipAPI?zip=",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
