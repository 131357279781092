import { NgModule,CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LeadFormComponent } from './components/lead-form/lead-form.component';
import { APIService } from './services/api.service';
import { InternetDealsFooterComponent } from './components/internet-deals-footer/internet-deals-footer.component';
import { InternetDealsHeaderComponent } from './components/internet-deals-header/internet-deals-header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
@NgModule({
  declarations: [
    LeadFormComponent,
    InternetDealsFooterComponent,
    InternetDealsHeaderComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    CarouselModule,
    BrowserAnimationsModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    NgbModule,
    InternetDealsFooterComponent,
    InternetDealsHeaderComponent,
    BrowserAnimationsModule
  ],
  providers:[
    APIService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class SharedModule { }
