<!-- Hero banner starts -->
<div class="hero-banner-bg">
    <div class="container hero-container">
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-8 col-xl-8">
            <div class="row hero-banner-heading-row">
                <div class="col-xs-12 col-sm-12 col-md-9 col-lg-8 col-xl-7 hero-banner-heading-div">
                    <h1 class="hero-banner-heading-text">
                        Comcast Internet Deals
                    </h1>
                </div>
            </div>

            <div class="row hero-banner-details-row">
                <div class="col-xs-12 col-sm-12 col-md-9 col-lg-8 col-xl-7 hero-banner-details-div">
                    <p class="hero-banner-details-text">Get fast, reliable internet at affordable prices in your
                        area.
                    </p>
                </div>
            </div>

            <div class="row hero-banner-details-row">
                <div class="col-xs-12 col-sm-12 col-md-12 hero-banner-details-div">
                    <p class="hero-banner-details-text">
                    </p>
                    <ul class="sub-feature-bullet-ul">
                        <li class="sub-feature-bullet font-main-color">No data caps</li>
                        <li class="sub-feature-bullet font-main-color">50 Mbps up to 1.25 Gbps download speeds</li>
                        <li class="sub-feature-bullet font-main-color">30-day money-back guarantee</li>
                        <li class="sub-feature-bullet font-main-color">Dedicated 24/7 customer support </li>

                    </ul>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 col-xl-4">
            <div class="customer-promotion-box">
                <div class="customer-promotion-header">
                    <p class="customer-promotion-header-text">
                        New customer promotions available
                    </p>
                    <p class="customer-promotion-header-sub-text">
                        START HERE
                    </p>
                </div>


                <div class="customer-promotion-body">
                    <p class="hero-banner-details-text text-center">
                        What type of Internet do you need?
                    </p>
                    <p>
                        <a class="customer-promotion-body-btn btn btn-primary" target="_blank"
                            href="https://www.jdoqocy.com/click-100817741-15258312?sid=CC-ComcastBusinessSavings">
                            Residential Home Internet</a>
                    </p>
                    <p>
                        <a class="customer-promotion-body-btn btn btn-primary" (click)="openModal()">
                            Business-Class Internet</a>
                    </p>
                    <p class="customer-promotion-body-tfn-box">
                        <a class="customer-promotion-body-tfn-box-text btn btn-default "
                            href="tel:+{{this.dataService.TFN}}">
                            <i class="fa fa-phone fa-flip-horizontal"></i> {{this.dataService.CallTFN}}</a>
                    </p>
                    <p class="customer-promotion-body-disclaimer">
                        Need Help? Call now to speak with an expert Comcast
                        Solutions Advisor

                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Hero banner ends -->

<div class="container spectrum-center-section">

    <div class="row spectrum-business-heading-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 spectrum-heading-div">
            <p class="spectrum-business-heading-text">Get the Best WiFi Features from Comcast</p>

        </div>
    </div>

    <div class="row spectrum-business-heading-row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <div class="slide spectrum-business-tile-div">
                <img src="../../../assets/images/internet-deals/comcast-gig-speeds.png" alt=""
                    class="spectrum-business-tile-div-img" width="60" height="60">
                <p class="spectrum-business-packages-slide-heading">The fastest internet with speeds faster than a gig
                </p>

            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <div class="slide spectrum-business-tile-div">
                <img src="../../../assets/images/internet-deals/comcast-network-reliability.png" alt=""
                    class="spectrum-business-tile-div-img" width="60" height="60">
                <p class="spectrum-business-packages-slide-heading">99.9% network
                    reliability</p>

            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <div class="slide spectrum-business-tile-div">
                <img src="../../../assets/images/internet-deals/comcast-advanced-cyber-threat-protection.png" alt=""
                    class="spectrum-business-tile-div-img" width="60" height="60">
                <p class="spectrum-business-packages-slide-heading">Protection from
                    cyber threats with Advanced Security</p>

            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <div class="slide spectrum-business-tile-div">
                <img src="../../../assets/images/internet-deals/comcast-xfi-coverage.png" alt=""
                    class="spectrum-business-tile-div-img" width="60" height="60">
                <p class="spectrum-business-packages-slide-heading">Wall-to-wall
                    coverage with an xFi Pod, if recommended</p>

            </div>
        </div>
    </div>

</div>

<!-- Best WiFi Features section starts -->
<section class="section-grey ">
    <div class="container spectrum-center-section">

        <div class="row spectrum-business-heading-row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 spectrum-heading-div">
                <p class="spectrum-business-heading-text">Explore Speeds for your Home</p>

            </div>
        </div>

        <div class="row spectrum-business-card-row">
            <owl-carousel-o [options]="customOptions" (changed)="getData($event)">
                <ng-template carouselSlide  let-owlItem >
                    <div [ngClass]="owlItem.isCentered ? 'highlight-div' : ''" >
                        <div class="spectrum-business-slide-div">
                            <div class="spectrum-business-slide-body-text">
                                <span class="pricing-text">Up to</span>
                                <span class="pricing-amount">50</span>
                                <p class="pricing-agreement">MBPS</p>
                            </div>
                            <ul class="spectrum-business-list">
                                <li class="spectrum-business-list-item">Up to 4 devices</li>
                                <li class="spectrum-business-list-item">Downloading music and photos</li>
                                <li class="spectrum-business-list-item">Streaming services</li>
                                <li class="spectrum-business-list-item">Video chatting</li>
                            </ul>
                            <p class="business-tv-learn-more">
                                <a style="text-decoration: none; color: #fff;" target="_blank"
                                    href="https://www.jdoqocy.com/click-100817741-15258312?sid=CC-ComcastBusinessSavings">Get
                                    Pricing
                                </a>
                            </p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide  let-owlItem >
                    <div  [ngClass]="owlItem.isCentered ? 'highlight-div' : ''">
                        <div class="spectrum-business-slide-div ">
                            <div class="spectrum-business-slide-body-text">
                                <span class="pricing-text">Up to</span>
                                <span class="pricing-amount">100</span>
                                <p class="pricing-agreement">MBPS</p>
                            </div>
                            <p class="spectrum-business-heading"></p>
                            <ul class="spectrum-business-list">

                                <li class="spectrum-business-list-item">Up to 5 devices</li>
                                <li class="spectrum-business-list-item">Downloading music and photos</li>
                                <li class="spectrum-business-list-item">Streaming services</li>
                                <li class="spectrum-business-list-item">Video chatting</li>
                            </ul>
                            <p class="business-tv-learn-more">
                                <a style="text-decoration: none; color: #fff;" target="_blank"
                                    href="https://www.jdoqocy.com/click-100817741-15258312?sid=CC-ComcastBusinessSavings">Get
                                    Pricing
                                </a>
                            </p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide  let-owlItem >

                    <div [ngClass]="owlItem.isCentered ? 'highlight-div' : ''">
                        <div class="spectrum-business-slide-div">
                            <div class="spectrum-business-slide-body-text">
                                <span class="pricing-text">Up to</span>
                                <span class="pricing-amount">200</span>
                                <p class="pricing-agreement">MBPS</p>
                            </div>
                            <p class="spectrum-business-heading"></p>
                            <ul class="spectrum-business-list">

                                <li class="spectrum-business-list-item">Up to 8 devices</li>
                                <li class="spectrum-business-list-item">Streaming services</li>
                                <li class="spectrum-business-list-item">Downloading large files</li>
                                <li class="spectrum-business-list-item">Video chatting</li>
                            </ul>
                            <p class="business-tv-learn-more">
                                <a style="text-decoration: none; color: #fff;" target="_blank"
                                    href="https://www.jdoqocy.com/click-100817741-15258312?sid=CC-ComcastBusinessSavings">Get
                                    Pricing
                                </a>
                            </p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide let-owlItem >

                    <div [ngClass]="owlItem.isCentered ? 'highlight-div' : ''">
                        <div class="spectrum-business-slide-div">
                            <div class="spectrum-business-slide-body-text">
                                <span class="pricing-text">Up to</span>
                                <span class="pricing-amount">400</span>
                                <p class="pricing-agreement">MBPS</p>
                            </div>
                            <p class="spectrum-business-heading"></p>
                            <ul class="spectrum-business-list">

                                <li class="spectrum-business-list-item">Up to 11 devices</li>
                                <li class="spectrum-business-list-item">Fast downloads</li>
                                <li class="spectrum-business-list-item">HD streaming</li>
                                <li class="spectrum-business-list-item">Multiplayer gaming</li>
                            </ul>
                            <p class="business-tv-learn-more">
                                <a style="text-decoration: none; color: #fff;" target="_blank"
                                    href="https://www.jdoqocy.com/click-100817741-15258312?sid=CC-ComcastBusinessSavings">Get
                                    Pricing
                                </a>
                            </p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide let-owlItem >

                    <div [ngClass]="owlItem.isCentered ? 'highlight-div' : ''">
                        <div class="spectrum-business-slide-div">
                            <div class="spectrum-business-slide-body-text">
                                <span class="pricing-text">Up to</span>
                                <span class="pricing-amount">1200</span>
                                <p class="pricing-agreement">MBPS</p>
                            </div>
                            <p class="spectrum-business-heading"></p>
                            <ul class="spectrum-business-list">

                                <li class="spectrum-business-list-item">12+ devices</li>
                                <li class="spectrum-business-list-item">Super fast downloads</li>
                                <li class="spectrum-business-list-item">Multiple people HD streaming</li>
                                <li class="spectrum-business-list-item">Multiplayer gaming</li>
                            </ul>
                            <p class="business-tv-learn-more">
                                <a style="text-decoration: none; color: #fff;" target="_blank"
                                    href="https://www.jdoqocy.com/click-100817741-15258312?sid=CC-ComcastBusinessSavings">Get
                                    Pricing
                                </a>
                            </p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide let-owlItem >

                    <div [ngClass]="owlItem.isCentered ? 'highlight-div' : ''">
                        <div class="spectrum-business-slide-div">
                            <div class="spectrum-business-slide-body-text">
                                <span class="pricing-text">Up to</span>
                                <span class="pricing-amount">800</span>
                                <p class="pricing-agreement">MBPS</p>
                            </div>
                            <p class="spectrum-business-heading"></p>
                            <ul class="spectrum-business-list">

                                <li class="spectrum-business-list-item">Unlimited devices</li>
                                <li class="spectrum-business-list-item">Ultra-connected households</li>
                                <li class="spectrum-business-list-item">Pro-level gaming</li>
                                <li class="spectrum-business-list-item">Staying ahead of the curve</li>
                            </ul>
                            <p class="business-tv-learn-more">
                                <a style="text-decoration: none; color: #fff;" target="_blank"
                                    href="https://www.jdoqocy.com/click-100817741-15258312?sid=CC-ComcastBusinessSavings">Get
                                    Pricing
                                </a>
                            </p>
                        </div>
                    </div>
                </ng-template>

                <div class="owl-dots" style="display: block;">
                        <div class="owl-dot active"><span></span></div>
                        <div class="owl-dot"><span></span></div>
                        <div class="owl-dot"><span></span></div>
                    </div>
            </owl-carousel-o>
        </div>


    </div>
</section>
<!-- Best WiFi Features section ends -->

<!-- Comcast Internet section starts -->
<div class="container spectrum-center-section">

    <div class="row spectrum-business-heading-row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 spectrum-heading-div">
            <p class="spectrum-business-heading-text">Why Choose Comcast Internet</p>

        </div>
    </div>

    <div class="row spectrum-business-heading-row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="slide spectrum-business-tile-div">

                <p class="spectrum-business-packages-slide-heading mb-4">No data caps </p>

                <img src="../../../assets/images/internet-deals/no-data-caps-icon.png" alt=""
                    class="spectrum-business-tile-div-img" width="60" height="">
                <p class="spectrum-business-list-item">Upload, download, share, and sync
                    without a limit or paying extra

                </p>

            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="slide spectrum-business-tile-div">
                <p class="spectrum-business-packages-slide-heading mb-4">24/7 customer service</p>
                <img src="../../../assets/images/internet-deals/comcast-customer-support-icon.png" alt=""
                    class="spectrum-business-tile-div-img " width="60" height="">
                <p class="spectrum-business-list-item">Get help when you need it via phone or
                    chat</p>

            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="slide spectrum-business-tile-div">
                <p class="spectrum-business-packages-slide-heading mb-4">30-day money-back
                    guarantee</p>
                <img src="../../../assets/images/internet-deals/30-day-money-back-fuarantee.png" alt=""
                    class="spectrum-business-tile-div-img" width="60" height="">
                <p class="spectrum-business-list-item">Our priority is ensuring your satisfaction</p>

            </div>
        </div>
    </div>

</div>
<!-- Comcast Internet section ends -->


<!-- Business Internet Speeds section starts -->
<section class="section-grey ">
    <div class="container spectrum-center-section">

        <div class="row spectrum-business-heading-row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 spectrum-heading-div">
                <p class="spectrum-business-heading-text">Comcast Business Internet Speeds</p>

            </div>
        </div>

        <div class="row spectrum-business-card-row">
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="spectrum-business-slide-div">
                    <p class="spectrum-business-slide-heading">FAST INTERNET +<br />
                        MOBILE SAVINGS</p>
                    <div class="spectrum-business-slide-body-text">
                        <span class="pricing-text">Up to</span>
                        <span class="pricing-amount">100</span>
                        <p class="pricing-agreement">MBPS</p>
                    </div>
                    <ul class="spectrum-business-list">
                        <li class="spectrum-business-list-item">BUSINESS INTERNET STANDARD</li>
                        <li class="spectrum-business-list-item">COMCAST BUSINESS SECURITYEDGE™</li>
                    </ul>
                    <p class="business-tv-learn-more">
                        <a style="text-decoration: none; color: #fff;"  (click)="openModal()">Get Free Quote
                        </a></p>
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="spectrum-business-slide-div highlight-div">
                    <p class="spectrum-business-slide-heading">MORE SPEED +<br />
                        MOBILE SAVINGS</p>
                    <div class="spectrum-business-slide-body-text">
                        <span class="pricing-text">Up to</span>
                        <span class="pricing-amount">500</span>
                        <p class="pricing-agreement">MBPS</p>
                    </div>
                    <p class="spectrum-business-heading"></p>
                    <ul class="spectrum-business-list">
                        <li class="spectrum-business-list-item">BUSINESS INTERNET advanced</li>
                        <li class="spectrum-business-list-item">COMCAST BUSINESS SECURITYEDGE™</li>
                        <li class="spectrum-business-list-item">CONNECTION PRO</li>
                    </ul>
                    <p class="business-tv-learn-more">
                        <a style="text-decoration: none; color: #fff;"  (click)="openModal()">Get Free Quote
                        </a></p>
                </div>
            </div>


            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="spectrum-business-slide-div">
                    <p class="spectrum-business-slide-heading">SUPER FAST +<br />
                        SECURITY</p>
                    <div class="spectrum-business-slide-body-text">
                        <span class="pricing-text">Up to</span>
                        <span class="pricing-amount">750</span>
                        <p class="pricing-agreement">MBPS</p>
                    </div>
                    <p class="spectrum-business-heading"></p>
                    <ul class="spectrum-business-list">
                        <li class="spectrum-business-list-item">BUSINESS INTERNET PREMIUM</li>
                        <li class="spectrum-business-list-item">COMCAST BUSINESS SECURITYEDGE™</li>
                        <li class="spectrum-business-list-item">CONNECTION PRO</li>
                        <li class="spectrum-business-list-item">SPECIAL OFFER</li>
                    </ul>
                    <p class="business-tv-learn-more">
                        <a style="text-decoration: none; color: #fff;"  (click)="openModal()">Get Free Quote
                        </a></p>
                </div>
            </div>
        </div>




    </div>
</section>
<!-- Business Internet Speeds section ends -->

<!-- Comcast Ethernet Dedicated Internet starts -->
<section class="section-grey ">
    <div class="right-padded-section">
        <div class="row spectrum-business-service-container">

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="image-box">
                    <img alt="spectrum wireless internet backup"
                        src="../../../assets/images/internet-deals/comcast-business-dedicated-internet.png">
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="row spectrum-business-service-heading-row">
                    <div
                        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 spectrum-business-service-heading-div">
                        <img alt="spectrum wireless internet backup"
                            src="../../../assets/images/internet-deals/comcast-dedicated-internet-icon.png">
                        <p class="spectrum-business-service-heading-text">Comcast Ethernet Dedicated Internet</p>

                        <p class="spectrum-business-service-subheading-text">
                            As your business and bandwidth needs grow, other legacy connections
                            may not keep pace. Ethernet, a standard way to connect wired
                            computers and other devices, plus local-area networks (LANs), with the
                            public Internet, can help. It oers the speed, scalability, and security that
                            a thriving business needs
                        </p>
                        <p class="spectrum-business-service-subheading-text">
                            Ethernet Dedicated Internet Access, also called Ethernet DIA, is a widely
                            used Ethernet option. It’s flexible, secure, and dependable. Not to
                            mention cost-eective
                        </p>

                        <p class="business-tv-learn-more">
                            <a style="text-decoration: none; color: #fff;"  (click)="openModal()">Get Free Quote
                            </a></p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- Comcast Ethernet Dedicated Internet ends -->


<!-- Comcast Business Voice Mobility starts -->
<section class="section-grey " style="display: flex;
justify-content: flex-end;">
    <div class="left-padded-section">
        <div class=" spectrum-business-service-container">


            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="row spectrum-business-service-heading-row">
                    <div
                        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 spectrum-business-service-heading-div">
                        <img alt="spectrum wireless internet backup"
                            src="../../../assets/images/internet-deals/comcast-business-voice-mobility-icon.png">
                        <p class="spectrum-business-service-heading-text">Comcast Business Voice Mobility</p>

                        <p class="spectrum-business-service-subheading-text">
                            <span class="bold-text">Easy number transfer</span><br />
                            We do the heavy lifting moving your existing number from
                            your current provider
                        </p>
                        <p class="spectrum-business-service-subheading-text">
                            <span class="bold-text">Get the right amount of lines</span><br />
                            Order up to 8 mobility voice lines online or ask us how to get
                            as many as 24

                        </p>

                        <p class="spectrum-business-service-subheading-text">
                            <span class="bold-text">Unlimited Nationwide Calling
                            </span><br />
                            Call anywhere in the U.S. for no additional charge

                        </p>


                        <p class="spectrum-business-service-subheading-text">

                            All plans include Nationwide Calling, Unlimited Local Calling,
                            and other line features to keep you connected. Got an existing
                            number? We work behind the scenes with your current
                            provider to ensure it comes with you.
                        </p>

                        <ul class="sub-feature-bullet-ul">
                            <li class="sub-feature-bullet font-secondary-color">Caller ID/Call History</li>
                            <li class="sub-feature-bullet font-secondary-color">Call Forwarding/Remote Call Forwarding
                            </li>
                            <li class="sub-feature-bullet font-secondary-color">Call Waiting</li>
                            <li class="sub-feature-bullet font-secondary-color">Advanced Call Hold and Transfer</li>
                            <li class="sub-feature-bullet font-secondary-color">Call Park/Call Pickup</li>
                            <li class="sub-feature-bullet font-secondary-color">Auto-Rerouting/Distinctive Ring</li>


                        </ul>
                        <p class="business-tv-learn-more">
                            <a style="text-decoration: none; color: #fff;"  (click)="openModal()">Get Free Quote
                            </a></p>
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="image-box">
                    <img alt="spectrum wireless internet backup"
                        src="../../../assets/images/internet-deals/comcast-business-voice-mobility.png">
                </div>
            </div>
        </div>

    </div>
</section>
<!-- Comcast Business Voice Mobility ends -->