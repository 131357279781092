<div class="container">
    <div class="row">
        <div class="col-xl-12  padding-0">
            <div class="">
                <form class="lead-form" [formGroup]="leadForm" autocomplete="off" (ngSubmit)="onLeadFormClick()">
                    <button type="button" class="close-btn" aria-label="Close" *ngIf="isModal"  (click)="onCLose()">×</button>
                    <h4 class="form-title">Please fill out the form below to request a call back from a Business Solutions Advisor</h4>
                    <div class="form-group">
                        <input class="form-control" type="text" formControlName="name" autocomplete="off"  placeholder="Contact Name*" required />
                        <div class="error" *ngIf="submitted && this.leadFormDetails.name.errors?.required">Please provide your contact name</div>
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" formControlName="businessName" autocomplete="off" placeholder="Business Name*" />
                        <div class="error" *ngIf="submitted && this.leadFormDetails.businessName.errors?.required">Please provide business name</div>
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" formControlName="businessAddress" autocomplete="tests" placeholder="Business Address*" />
                        <div class="error" *ngIf="submitted && this.leadFormDetails.businessAddress.errors?.required">Please provide business address</div>
                    </div>
                    <div class="form-group">
                        <input #pn class="form-control" type="text" formControlName="phone" autocomplete="off" placeholder="Phone*" (blur)="formatPhoneNumber(pn.value)" />
                        <div class="error" *ngIf="submitted && this.leadFormDetails.phone.errors?.required">Please provide phone</div>
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="email" formControlName="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" autocomplete="off" placeholder="Email*" />
                        <div class="error" *ngIf="submitted && (this.leadFormDetails.email.errors?.required || this.leadFormDetails.email.invalid)">Please provide your valid email</div>
                    </div>                    
                    <div class="form-group">
                        <textarea class="form-control" rows="4" cols="50" formControlName="message" autocomplete="off" placeholder="Message (Optional)"></textarea>
                    </div>
                    <p class="required-label"> *Required fields</p>
                    <div class="submit-btn-div">
                        <button class="submit-btn" type="submit"><i *ngIf="loading" style="margin-right: 12px;"  class="fas fa-circle-notch fa-spin"></i>{{submitButtonText}}</button>
                    </div>
                    <div class="form-disclaimer-div">
                        <p class="form-disclaimer-text">
                            By choosing REQUEST CALL BACK NOW, I provide my signature,
                            expressly consenting to contact from ClearConnectCo.com,
                            or its subsidiaries, at the number provided regarding its
                            products or customer service via live, prerecorded telephone call,
                            or email message. I understand that my telephone company may
                            impose charges on me for these contacts and I am not required to sign
                            this document as a condition to purchase any good or service.
                            I understand that I can revoke this consent at any time.
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>